import React from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { endsOnNextDay, isValidHour } from './locationHoursOfOperationUtil';
import { ALL_HOURS, CLOSE_TIME, OPEN_TIME, IS_CLOSED, OPEN_24_HOURS } from './locationManagementConstants';

const hourRegex = /^(\d*(:\d*)?)$|^(24\s?(h(r(s)?)?)?)$/; // match numbers, a colon followed by numbers, or "24 hrs"

const getValue = (dayHoursOfOperation, field) => {
  let returnValue = dayHoursOfOperation?.[field];
  if (field === OPEN_TIME) {
    returnValue = dayHoursOfOperation[OPEN_24_HOURS] === true ? '24 hrs' : dayHoursOfOperation[OPEN_TIME];
  }

  return returnValue == null ? '' : returnValue;
};

const HourField = ({
  dayHoursOfOperation,
  isLoading,
  field,
  setLocationHoursOfOperation,
  errorFields,
  hideClearButton = false,
  validateHour = false,
}) => {
  const [currentValue, setCurrentValue] = React.useState(getValue(dayHoursOfOperation, field));
  const [showOptions, setShowOptions] = React.useState(false);
  const containerRef = React.useRef(null);

  React.useEffect(() => {
    const newValue = getValue(dayHoursOfOperation, field);
    setCurrentValue(newValue);
  }, [dayHoursOfOperation, field]);

  const options = React.useMemo(() => {
    if (field === OPEN_TIME) {
      return ['24 hrs', ...ALL_HOURS];
    }
    return ALL_HOURS;
  }, [field]);

  const setValue = value => {
    const otherField = field === OPEN_TIME ? CLOSE_TIME : OPEN_TIME;
    let newValue = {
      [OPEN_24_HOURS]: false,
      [IS_CLOSED]: false,
      [field]: value,
      [otherField]: dayHoursOfOperation[otherField],
    };

    if (value === '24 hrs' && field === OPEN_TIME) {
      newValue = {
        [OPEN_24_HOURS]: true,
        [IS_CLOSED]: false,
        [OPEN_TIME]: '',
        [CLOSE_TIME]: '',
      };
    } else if (value === '00:00' && dayHoursOfOperation[otherField] === '00:00') {
      newValue = {
        [IS_CLOSED]: false,
        [OPEN_24_HOURS]: true,
        [OPEN_TIME]: '',
        [CLOSE_TIME]: '',
      };
    }

    if (!validateHour || newValue[IS_CLOSED] || newValue[OPEN_24_HOURS] || isValidHour(newValue[field])) {
      setLocationHoursOfOperation(dayHoursOfOperation.day, newValue);
    } else {
      setCurrentValue(getValue(dayHoursOfOperation, field));
    }
  };

  const handleSetValue = () => {
    if (currentValue == null || currentValue.length === 0) {
      setValue('');
      return;
    }

    const [hour, minutes] = currentValue.split(':').map(Number);

    if (hour >= 0 && hour < 24 && minutes >= 0 && minutes < 60) {
      setValue(`${hour.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`);
    } else if (hour >= 0 && hour < 24) {
      setValue(`${hour.toString().padStart(2, '0')}:00`);
    } else {
      setValue(currentValue);
    }
  };

  const handleInputChange = event => {
    const { value } = event.target;
    if (hourRegex.test(value)) {
      setCurrentValue(value);
    }
  };

  const handleInputFocus = () => {
    setShowOptions(true);
  };

  const selectOption = option => {
    setValue(option);
    setShowOptions(false);
  };

  const onBlur = () => {
    if (showOptions === false) {
      // Field was not active
      return;
    }

    handleSetValue();
  };

  React.useEffect(() => {
    const handleClickOutside = event => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        onBlur();
        setShowOptions(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isNextDay =
    field === CLOSE_TIME && endsOnNextDay(dayHoursOfOperation[OPEN_TIME], dayHoursOfOperation[CLOSE_TIME]);
  const isError =
    errorFields &&
    errorFields.some(errorField => errorField.day === dayHoursOfOperation.day && errorField.field === field);
  const isHidden = dayHoursOfOperation[IS_CLOSED] || (dayHoursOfOperation[OPEN_24_HOURS] && field === CLOSE_TIME);

  const filteredOptions = options.filter(option => option.toLowerCase().includes(currentValue.toLowerCase()));

  return (
    <div className="hours-of-operation-input" ref={containerRef}>
      <input
        type="text"
        className={classnames('hour-input', { error: isError, hidden: isHidden })}
        placeholder={isHidden ? '-' : '--:--'}
        value={currentValue}
        onChange={handleInputChange}
        onFocus={handleInputFocus}
        onBlur={handleSetValue}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            e.target.blur();
            onBlur();
            setShowOptions(false);
          }
        }}
        disabled={isLoading || isHidden}
      />
      {isNextDay && <span className="next-day">Next Day</span>}
      {currentValue?.length > 0 && !hideClearButton && (
        <button
          type="button"
          className="clear-button"
          onClick={() => {
            setValue('');
          }}
          disabled={isLoading || isHidden}
        >
          <FontAwesomeIcon icon={faXmark} className="clear-icon" />
        </button>
      )}
      {showOptions && (
        <div className="options-container">
          {filteredOptions.map(option => (
            <button type="button" className="option" key={option} onClick={() => selectOption(option)}>
              {option}
            </button>
          ))}
          {filteredOptions.length === 0 && <div className="no-options">No results</div>}
        </div>
      )}
    </div>
  );
};

export default HourField;
